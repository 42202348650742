<template>
    <div class="citation-list">
      <kendo-datasource
            :ref="'studyMappingSelectorDataSource'"
            :data="selector">
        </kendo-datasource>
      <div style="display: flex; flex-direction:row; border: 0px solid black; justify-content: flex-start; height:25px;">
        <div>Grouped by:&nbsp;</div>
        <kendo-dropdownlist
          class="StudyMappingDropdown"
          :id="'studyMappingSelector'"
          :data-source-ref="'studyMappingSelectorDataSource'"
          :data-value-field="'SelectorIdentity'"
          :data-text-field="'SelectorName'"
          :change="changeSelector"
          :auto-width="true"
          >
        </kendo-dropdownlist>
        <div>Sort by:&nbsp;</div>
        <kendo-datasource
            :ref="'sortSelectorDataSource'"
            :data="sortStudySelector">
        </kendo-datasource>
        <kendo-dropdownlist
          class="StudyMappingDropdown"
          :id="'sortSelector'"
          :data-source-ref="'sortSelectorDataSource'"
          :data-value-field="'SelectorIdentity'"
          :data-text-field="'SelectorName'"
          :auto-width="true"
          >
        </kendo-dropdownlist>
    </div>
    <div>
      <input type="checkbox"/>Show Forms with only one data from one extractor</div>
      <div style='width: 100%; display: flex; justify-content: flex-start;' class='k-grid-toolbar' id="myToolbar">
        <button id="studyAll" style="height: 25px; padding-left: 20px; padding-right: 10px;" @click='openReviewByCitations()'><u>E</u>xport To Excel</button>
      </div>
      <kendo-datasource :ref="'citationDataDiscrepancyDataSource'" :data="citationDataDiscrepancyDataSource.data"></kendo-datasource>
      <kendo-datasource :ref="'studyDataDiscrepancyDataSource'" :data="studyDataDiscrepancyDataSource.data"></kendo-datasource>
      <kendo-grid
        :id="'citation-list-grid'"
        :ref="'citationListGrid'"
        :data-source-ref="'citationDataDiscrepancyDataSource'"
        style="citation-list-grid"
        :groupable="groupable"
        :selectable="selectable"
        :filterable="filterable"
        :sortable="sortable"
        :pageable="pageable"
        :columns="columnsCitations"
        :toolbar="toolbar"
        :scrollable="scrollable"
        v-if="visible0">
      </kendo-grid>
      <kendo-grid
        :id="'study-list-grid'"
        :ref="'studyListGrid'"
        :data-source-ref="'studyDataDiscrepancyDataSource'"
        style="citation-list-grid"
        :groupable="groupable"
        :selectable="selectable"
        :filterable="filterable"
        :sortable="sortable"
        :pageable="pageable"
        :columns="columnsStudies"
        :toolbar="toolbar"
        :scrollable="scrollable"
        v-if="visible1">
      </kendo-grid>
    </div>
</template>

<script>
import CitationDataDiscrepancyDataSource from '@/assets/data/CitationDataDiscrepancy.json'
import StudyDataDiscrepancyDataSource from '@/assets/data/StudyDataDiscrepancy.json'

export default {
  name: 'citation-list-discrepancies',
  data () {
    return {
      visible0: true,
      visible1: false,
      pageable: {
        refresh: true,
        pageSizes: true,
        buttonCount: 2,
        pageSize: 5
      },
      selector: [
        { SelectorIdentity: 2, SelectorName: 'Citations' },
        { SelectorIdentity: 3, SelectorName: 'Studies' }
      ],
      sortStudySelector: [
        { SelectorIdentity: 3, SelectorName: 'Number of citations Low to High' },
        { SelectorIdentity: 4, SelectorName: 'Number of Citations High to Low' }
      ],
      sortCitationSelector: [
        { SelectorIdentity: 1, SelectorName: 'Low to High Number of mapped studies' },
        { SelectorIdentity: 2, SelectorName: 'High to Low Number of mapped studies' }
      ],
      columnsCitations: [
        {
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'PublicationIdentity', title: 'Citation ID', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } },
            { field: 'CurrentStage', title: 'Current Stage', width: '1fr', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Author', title: 'Author', width: '1fr', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Year', title: 'Year', width: '1fr', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Title', title: 'Title', width: '1fr', headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Study',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'RegistryID', title: 'Registry #', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } },
            { field: 'StudyID', title: 'StudyID', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Form 1/Extractor 1',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'Pass1', title: ' ', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' }, template: '<div id="#:Pass1#"></div>' }
          ]
        },
        {
          title: 'Form 1/Extractor 2',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'Pass2', title: ' ', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' }, template: '<div id="#:Pass2#"></div>' }
          ]
        }
      ],
      columnsStudies: [
        {
          title: 'Study',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'RegistryID', title: 'Registry #', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-light' } },
            { field: 'StudyID', title: 'Study ID', width: '1fr', headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Citation',
          headerAttributes: { class: 'grid-header-light' },
          columns: [
            { field: 'OriginalStage', title: 'Current Stage', width: '1fr', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Author', title: 'Author', width: '1fr', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Year', title: 'Year', width: '1fr', headerAttributes: { class: 'grid-header-light' } },
            { field: 'Title', title: 'Title', width: '1fr', headerAttributes: { class: 'grid-header-light' } }
          ]
        },
        {
          title: 'Form 1/Extractor 1',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'DE2To', title: ' ', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' }, template: '<div id="#:Pass1#"></div>' }
          ]
        },
        {
          title: 'Form 1/Extractor 2',
          headerAttributes: { class: 'grid-header-dark' },
          columns: [
            { field: 'DE2To', title: ' ', width: '1fr', filterable: false, headerAttributes: { class: 'grid-header-dark' }, template: '<div id="#:Pass2#"></div>' }
          ]
        }
      ],
      filterable: false,
      groupable: false,
      selectable: 'multiple row',
      sortable: true,
      scrollable: true,
      citationDataDiscrepancyDataSource: CitationDataDiscrepancyDataSource,
      studyDataDiscrepancyDataSource: StudyDataDiscrepancyDataSource
    }
  },
  methods: {
    changeSelector: function (e) {
      var dsSortWidget = this.$refs.sortSelectorDataSource.kendoWidget()
      switch (e.sender.selectedIndex) {
        case 0:
          // Grouped by Studies
          dsSortWidget.data(this.sortStudySelector)
          this.visible0 = true
          this.visible1 = false
          break
        case 1:
          // Grouped by Citation
          dsSortWidget.data(this.sortCitationSelector)
          this.visible0 = false
          this.visible1 = true
          break
      }
    }
  },
  mounted: function () {
    this.gridWidget = this.$refs.citationListGrid.kendoWidget()
    this.gridWidget.refresh()
  }
}
</script>

<style>
  #icon-check {
    background-image: url(../../../assets/smallsuccess.gif);
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }

  #icon-cross {
    background-image: url(../../../assets/smallfail.gif);
    width: 20px;
    height: 20px;
    background-size: 20px 20px;
  }
</style>

<template>
  <div class="splitter-container">
    <div class="panel-header">
        <div class="panel-header-title">{{ title }}</div>
        <div class="panel-header-pins">
          <a href="#" style="transform: rotate(90deg);" v-if="swap" :title="'Swap'">
              <span class="k-icon k-i-arrows-swap"></span>
          </a>
          <a href="#" @click="openStudyCitationsList()" v-if="filterstudycitations" :title="title">
            <span class="k-icon k-i-filter"></span>
          </a>
          <a href="#" @click="openCitationStudiesList()" v-if="filterCitationStudies" :title="title">
            <span class="k-icon k-i-filter"></span>
          </a>
          <a href="#" @click="openCitationStudiesList()" v-if="filter" :title="filtertitle">
            <span class="k-icon k-i-filter"></span>
          </a>
          <a href="#" v-if="info" :title="infotitle">
            <span class="k-icon k-i-info"></span>
          </a>
          <a href="#" @click="toggleDetach()" v-if="detach" :title="detachtitle">
            <span class="k-icon k-i-hyperlink-open"></span>
          </a>
          <a href="#" @click="toggleDetach()" v-if="aggregate" :title="aggregatetitle">
            <span class="k-icon k-i-aggregate-fields"></span>
          </a>
          <a href="#" v-if="edit" :title="edititle">
            <span class="k-icon k-i-pencil"></span>
          </a>
          <a href="#" @click="openTags()" v-if="tag" :title="tagtitle">
              <span class="k-icon k-i-comment"></span>
          </a>
        </div>
    </div>
    <component class="component" :is="component" :style="calculateHeight()" :editable="editable"></component>
    <kendo-window :ref="'detachWindow'"
                  :width="'100%'"
                  :title="title"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="component" :style="calculateHeight()"></component>
    </kendo-window>
    <kendo-window :ref="'popupStudyCitations'"
                  :width="'80%'"
                  :title="title"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="popup" :style="calculateHeight()"></component>
    </kendo-window>
    <kendo-window :ref="'popupCitationStudies'"
                  :width="'80%'"
                  :title="title"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <component class="component" :is="popup" :style="calculateHeight()"></component>
    </kendo-window>
    <kendo-window :ref="'popupTags'"
                  :width="'50%'"
                  :title="'Tag creation'"
                  :visible="false"
                  :modal="true"
                  :top="'0px'">
        <kendo-hierarchicaldatasource
          :ref="'tagDataSourceRef'"
          :data="tagsData.data"
          :schema="tagsData.schema">
        </kendo-hierarchicaldatasource>
        <kendo-dropdowntree
          :data-source-ref="'tagDataSourceRef'"
          :data-text-field="'tag'"
          :data-value-field="'tag'"
          :checkboxes-check-children="true"
          :check-all="false"
          :tag-mode="'multiple'"
          style="width: calc(100% - 250px);">
        </kendo-dropdowntree>
        <kendo-button>Save Tags</kendo-button>
        <kendo-button>Cancel</kendo-button>
    </kendo-window>
  </div>
</template>

<script>
import Filters from '@/views/secure/cards/Filters'
import FiltersBatchReview from '@/views/secure/cards/FiltersBatchReview'
import CitationList from '@/views/secure/cards/CitationList'
import CitationListDiscrepancies from '@/views/secure/cards/CitationListDiscrepancies'
import CitationListDataDiscrepancies from '@/views/secure/cards/CitationListDataDiscrepancies'
import CitationListValidation from '@/views/secure/cards/CitationListValidation'
import CitationListBatchReview from '@/views/secure/cards/CitationListBatchReview'
import Annotations from '@/views/secure/cards/Annotations'
import AnnotationsBatchReview from '@/views/secure/cards/AnnotationsBatchReview'
import Abstract from '@/views/secure/cards/Abstract'
import AbstractBatchReview from '@/views/secure/cards/AbstractBatchReview'
import Citation from '@/views/secure/cards/Citation'
import CitationBatchReview from '@/views/secure/cards/CitationBatchReview'
import ActionsManageStagesForms from '@/views/secure/cards/ActionsManageStagesForms'
import ActionsNavigateCitations from '@/views/secure/cards/ActionsNavigateCitations'
import ActionsNavigateCitationStudies from '@/views/secure/cards/ActionsNavigateCitationStudies'
import ActionsNavigateStudies from '@/views/secure/cards/ActionsNavigateStudies'
import ActionsDE from '@/views/secure/cards/ActionsDE'
import ActionsBatch from '@/views/secure/cards/ActionsBatch'
import ActionsStageDiscrepancies from '@/views/secure/cards/ActionsStageDiscrepancies'
import ActionsDataDiscrepancies from '@/views/secure/cards/ActionsDataDiscrepancies'
import ActionsValidation from '@/views/secure/cards/ActionsValidation'
import ActionsBatchReview from '@/views/secure/cards/ActionsBatchReview'
import Forms from '@/views/secure/cards/Forms'
import FormsDataDiscrepancies from '@/views/secure/cards/FormsDataDiscrepancies'
import FullText from '@/views/secure/cards/FullText'
import FullTextBatchReview from '@/views/secure/cards/FullTextBatchReview'
import PopUpCitationStudies from '@/views/secure/cards/PopUpCitationStudies'
import PopUpStudyCitations from '@/views/secure/cards/PopUpStudyCitations'
import TagsData from '@/assets/data/Tags.json'

export default {
  name: 'SplitterContainer',
  props: ['title', 'detach', 'detachtitle', 'aggregate', 'aggregatetitle', 'edit', 'edittitle', 'swap', 'swaptitle', 'filter', 'filtertitle', 'tag', 'tagtitle', 'filterstudycitations', 'filtercitationstudies', 'info', 'infotitle', 'component', 'popup', 'editable'],
  components: {
    Filters,
    FiltersBatchReview,
    CitationList,
    CitationListDiscrepancies,
    CitationListDataDiscrepancies,
    CitationListValidation,
    CitationListBatchReview,
    Annotations,
    AnnotationsBatchReview,
    Abstract,
    AbstractBatchReview,
    Citation,
    CitationBatchReview,
    ActionsManageStagesForms,
    ActionsNavigateCitations,
    ActionsNavigateStudies,
    ActionsNavigateCitationStudies,
    ActionsDE,
    ActionsBatch,
    ActionsDataDiscrepancies,
    ActionsStageDiscrepancies,
    ActionsValidation,
    ActionsBatchReview,
    Forms,
    FormsDataDiscrepancies,
    FullText,
    FullTextBatchReview,
    PopUpCitationStudies,
    PopUpStudyCitations
  },
  data () {
    return {
      tagsDataSource: [
        { value: '1', text: 'Tag 1' },
        { value: '2', text: 'Tag 2' },
        { value: '3', text: 'Tag 3' },
        { value: '4', text: 'Tag 4' }
      ],
      tagsData: TagsData
    }
  },
  methods: {
    calculateHeight: function () {
      if (this.component === 'citation-list' | this.component === 'citation-list-discrepancies') {
        return 'height: calc(100% - 90px)'
      } else {
        return 'height: calc(100% - 30px)'
      }
    },
    toggleDetach: function () {
      var windowWidget = this.$refs.detachWindow.kendoWidget()
      windowWidget.open()
      windowWidget.maximize()
    },
    openStudyCitationsList: function () {
      var windowWidget = this.$refs.popupStudyCitations.kendoWidget()
      windowWidget.open()
    },
    openCitationStudiesList: function () {
      var windowWidget = this.$refs.popupCitationStudies.kendoWidget()
      windowWidget.open()
    },
    openTags: function () {
      var windowWidget = this.$refs.popupTags.kendoWidget()
      windowWidget.open()
    }
  }
}
</script>

<style scoped>
  .k-icon:before {
    color: #263246 !important;
  }
</style>
